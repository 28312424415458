import { ReviewType } from '@utils/types/review';



export const reviews: ReviewType[] = [
  {
    locationName: `Rockwell Place`,
    review:
        '“This is a wonderful house for 6 or more people. Very clean, spacious, and well-stocked with towels, toiletries, multiple hair dryers, pots, pans, casserole dishes, cooking utensils, dishes, plates, multiple coffee machines, etc. etc…you get the idea. Pictures on the website are accurate. Modern, funky furniture and artwork. Spacious bathrooms, good lighting. Amazing kitchen with top-of-the-line appliances and huge workspace for those who want to cook. Also a kitchenette downstairs. The neighborhood is quiet (for Brooklyn) and there is every kind of bodega imaginable within easy walking distance. Communication with property management was prompt and courteous. No trouble entering or leaving the unit. Easy instructions. We would definitely stay here again.”',
    image: '/images/rockwell-place.jpg',
    rating: 5,
    listingID: '6144ad571f6ef6002ef51f94',
    userInfo: {
      companyName: '',
      profilePicture:
          '',
      userName: 'Dana',
    },
  },
  {
    locationName: `Ridge Crest`,
    review:
        '“Staying at Ridge Crest by Rove was a fantastic way to start our work remote adventure! We had our 7-month old and dog with us and had plenty of room for a week-long stay. The house was clean, kitchen was stocked so we could cook dinner in the evenings, it was set up perfectly for working remote (as described - Herman miller chairs, stand up desks, extra monitors, etc.) and the house and view is beautiful! The host, Patrick, was very communicative and helped me when I locked us out of the house.”',
    image: '/images/ridge-crest.jpg',
    rating: 5,
    listingID: '60cc9090ed9056002ee5f644',
    userInfo: {
      companyName: '',
      profilePicture:
          '',
      userName: 'Jeannette',
    },
  },
  {
    locationName: `Ridge Crest`,
    review:
        '“The view from this cozy home is absolutely stunning! The house was comfortable, met all of our needs, the hot tub was great after a day of skiing. Well worth a little drink g to get to the slopes! I would love to stay here again!”',
    image: '/images/ridge-crest.jpg',
    rating: 5,
    listingID: '60cc9090ed9056002ee5f644',
    userInfo: {
      companyName: '',
      profilePicture:
          '',
      userName: 'Jill',
    },
  },
];
