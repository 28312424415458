import { BaseComponentProps } from '@utils/types/baseComponents';
import clsx from 'clsx';
import React, { useState } from 'react';
import Link from 'next/link';
import styles from './styles.module.scss';
import {
  Ergonomic,
  Star,
  Keyboard,
  EspressoMachine,
  ParachuteLinens,
  Outdoor,
} from 'assets/icons';
import { ArrowButton } from '@atoms/arrowButton';
import { colors } from '@utils/constants/colors';
import { Image } from '@atoms/image';

type Props = {} & BaseComponentProps;

const options = [
  {
    label: 'Ergonomic workspaces',
    text: 'Fully equipped workspaces with standing desks, monitors, keyboards, mice, ergonomic chairs, and whiteboards for collaboration.',
    icon: (props: any) => <Ergonomic {...props} />,
    image: '/images/ergonomic-workspaces.jpg',
  },
  {
    label: 'Luxury amenities',
    text: 'High end amenities such as Maelin-Goetz toiletries, chef grade kitchen items, high thread count linens -- and much more.',
    icon: (props: any) => <ParachuteLinens {...props} />,
    image: '/images/luxury-amenities.jpg',
  },
  {
    label: 'Fully equipped kitchens',
    text: 'Large kitchens equipped for cooking and entertaining, including Nespresso machines, chefs grade appliances, and spices.',
    icon: (props: any) => <EspressoMachine {...props} />,
    image: '/images/fully-equipped-kitchens.webp',
  },
  {
    label: 'Premier locations',
    text: 'Curated homes in desirable locations.',
    icon: (props: any) => <Star fill={colors.roveBlack} {...props} />,
    image: '/images/premier-locations.jpg',
  },
  {
    label: 'Elevated service',
    text: 'Contactless check-in/check-out and dedicated, on-call customer service for all of your needs.',
    icon: (props: any) => <Keyboard {...props} />,
    image: '/images/elevated-service.jpg',
  },
  {
    label: 'Beautiful outdoor spaces',
    text: 'Curated outdoor spaces for working, entertaining, and collaborating',
    icon: (props: any) => <Outdoor {...props} />,
    image: '/images/beautiful-outdoor-spaces.png',
  },
];

export function RoveDifferenceSelector(props: Props) {
  const { containerStyle } = props;
  const [selectedOption, setSelectedOption] = useState(0);

  const renderSelectedOption = () => {
    return (
      <>
        <div className={clsx(styles.optionImage)}>
          <Image src={options[selectedOption].image} alt='image' />
        </div>
        <div className={clsx(styles.optionDescription)}>
          <div className={clsx(styles.descriptionContainer)}>
            {options[selectedOption].icon({ className: clsx(styles.icon) })}
            <span className={styles.title}>
              {options[selectedOption].label}
            </span>
            <span className={styles.description}>
              {options[selectedOption].text}
            </span>
            <Link href={'/search'}>
              <a>
                <ArrowButton
                  label={'Explore properties'}
                  containerStyle={clsx(styles.button)}
                />
              </a>
            </Link>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={clsx([styles.container, containerStyle])}>
      <div className={clsx(styles.optionsContainer)}>
        {options.map((option, idx) => (
          <div
            className={clsx([
              styles.option,
              selectedOption === idx && styles.selectedOption,
            ])}
            key={idx}
            onClick={() => setSelectedOption(idx)}>
            <span className={clsx(styles.optionText)}>{option.label}</span>
          </div>
        ))}
      </div>
      {renderSelectedOption()}
    </div>
  );
}
