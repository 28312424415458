import { Button } from '@atoms/button';
import { DateRangePicker } from '@atoms/dateRangePicker';
import { Select } from '@atoms/select';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { DatesRange } from '@utils/types/calendar';
import { MarketType } from '@utils/types/city';
import { SegmentEventNamesEnum, SegmentEventPagesEnum, useAnalytics } from '@utils/hooks/useAnalytics';
import { City } from 'assets/icons';
import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './styles.module.scss';

type Props = {
  markets: MarketType[];
  onSearch: (market: string, dates: DatesRange) => void;
  defaultMarket?: string;
} & BaseComponentProps;

export function MarketDateSelector(props: Props) {
  const { containerStyle, onSearch, markets, defaultMarket } = props;
  const isMobile = useIsMobile();
  const [market, setMarket] = useState<string| null>(defaultMarket || null);
  const [dateRange, setDateRange] = useState<DatesRange>({
    from: null,
    to: null,
  });
  const { trackEvent } = useAnalytics();

  const onMarketChange = (market: string) => {
    trackEvent(SegmentEventNamesEnum.SELECT_MARKET_CLICKED, {
      PageName: SegmentEventPagesEnum.HOME_PAGE
    });
    setMarket(market);
  }

  const onSearchClick = () => {
    trackEvent(SegmentEventNamesEnum.SEARCH_CLICK, {
      PageName: SegmentEventPagesEnum.HOME_PAGE
    });
    onSearch(market || '', dateRange);
  };

  return (
    <div className={clsx([styles.container, containerStyle])}>
      <div className={styles.selectorContainer}>
        {!isMobile && (
          <div className={styles.icon}>
            <City />
          </div>
        )}
        <Select
          variant="secondary"
          multiple={false}
          options={markets.map((c) => ({ label: c.name, value: c.slug }))}
          onSelected={onMarketChange}
          placeholder="Select a Market"
          containerStyle={styles.selector}
          customEmptyValue={{ label: 'All Markets', value: '' }}
          value={market}
        />
      </div>

      {!isMobile && (
        <div className={styles.dividerContainer}>
          <div className={styles.divider}></div>
        </div>
      )}
      <DateRangePicker
        showIcon
        variant='secondary'
        onRangeSelected={setDateRange}
        containerStyle={styles.dateRangeContainer}
      />
      <Button
        label='Search'
        onClick={onSearchClick}
        containerStyle={styles.searchButton}
      />
    </div>
  );
}
