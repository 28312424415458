import { ListingPhoto } from '@atoms/listingPhoto';
import { toDollars } from '@utils/money';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { FeaturedListingType } from '@utils/types/featuredListing';
import {
  SegmentEventNamesEnum,
  SegmentEventPagesEnum,
  useAnalytics,
} from '@utils/hooks/useAnalytics';
import { listingTitleWithoutByRove } from '@utils/helpers';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { Star } from 'assets/icons';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  layout: 'horizontal' | 'vertical';
  listing: FeaturedListingType;
} & BaseComponentProps;

ListingInfoCard.defaultProps = {
  layout: 'horizontal',
};

export function ListingInfoCard(props: Props) {
  const { containerStyle, listing } = props;
  const { trackEvent } = useAnalytics();
  const isMobile = useIsMobile();
  const router = useRouter();

  const onListingClick = () => {
    trackEvent(SegmentEventNamesEnum.FEATURED_LISTING_CLICKED, {
      PageName: SegmentEventPagesEnum.HOME_PAGE,
    });
  };

  return (
    <Link href={`/listing/${listing.import_listing_id}`} passHref>
      <a>
        <div
          className={clsx([styles.listingInfoCardContainer, containerStyle])}
          onClick={onListingClick}
        >
          <div className={styles.header}>
            <span className={styles.title}>{listingTitleWithoutByRove(listing.title)}</span>

            <div className={styles.secondRow}>
              <span className={styles.subtitle}>
                {listing.address.city},&nbsp;{listing.address.state}
              </span>
              <div className={styles.rating}>
                <Star />
                {listing.rating} of 5.0
              </div>
            </div>
          </div>
          <div className={styles.body}>
            {listing.photos[0] && (
              <ListingPhoto
                photos={listing.photos}
                containerStyle={styles.imageContainer}
                onListingClick={() => router.push(`/listing/${listing.import_listing_id}`)}
              />
            )}
          </div>
          <div className={styles.footer}>
            <div className={styles.price}>
              From&nbsp;
              {listing.min_nightly_price
                ? toDollars(listing.min_nightly_price)
                : toDollars(listing.sixty_days_average_price)}
              {isMobile && ' '}/night
            </div>
            <div className={styles.features}>
              <div className={styles.feature}>
                Guests
                <br />
                <span>{listing.accommodates}</span>
              </div>
              <div className={styles.feature}>
                Beds
                <br />
                <span>{listing.bedrooms_total}</span>
              </div>
              <div className={styles.feature}>
                Baths
                <br />
                <span>{listing.bathrooms}</span>
              </div>
              <div className={styles.feature}>
                Workstations
                <br />
                <span>{listing.offices_total}</span>
              </div>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
}
