import { getHomepageContent } from '@api/homepage/getContent';
import { getCollectionsByType } from '@api/collections/getCollectionsByType';
import { ArrowButton } from '@atoms/arrowButton';
import { Background } from '@atoms/background';
import { Image } from '@atoms/image';
import { MarketDateSelector } from '@molecules/marketDateSelector';
import { ListingInfoList } from '@molecules/listingsInfoList';
import { ListingsByMarket } from '@organisms/listingsByMarket';
import { Reviews } from '@organisms/reviews';
import { reviews } from '@organisms/reviews/constants';
import { RoveDifferenceSelector } from '@organisms/roveDifferenceSelector';
import { withLayout, withSession } from '@utils/hocs';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { DatesRange } from '@utils/types/calendar';
import { MarketType } from '@utils/types/city';
import { FeaturedListingType } from '@utils/types/featuredListing';
import { PageWithProvidersAndLayout } from '@utils/types/nextApp';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

type Props = {
  markets: MarketType[];
  featuredListings: FeaturedListingType[];
};

type StaticProps = {
  props: Props;
  revalidate: Number;
};

const Home: PageWithProvidersAndLayout<Props> = (props: Props) => {
  const { featuredListings, markets } = props;
  const router = useRouter();
  const isMobile = useIsMobile();
  const [listingsByMarket, setListingsByMarket] = useState<
    Record<string, FeaturedListingType[]>
  >({});

  const onMarketAndDatesSelected = (market: string, { from, to }: DatesRange) => {
    router.push({
      pathname: '/search',
      query: {
        ...(!!market && { market }),
        ...(!!from && !!to && { dates: [from.toISOString(), to.toISOString()] }),
      },
    });
  };

  useEffect(() => {
    const tempObj: Record<string, FeaturedListingType[]> = {};
    markets.forEach((m) => {
      const listings = featuredListings.filter(
        (l) => l.collections.includes(m.slug)
      );
      if (listings.length !== 0) {
        const guestyIds =
          m.relatedListings?.filter((el) => !!el).map((el) => el.guestyId) || [];
        tempObj[m.slug] = listings
          .sort((a, b) =>
            guestyIds.indexOf(a.import_listing_id) - guestyIds.indexOf(b.import_listing_id)
          );
      }
    });
    setListingsByMarket(tempObj);
  }, [featuredListings, markets]);

  return (
    <div className={clsx(styles.container)}>
      <Background withImage containerStyle={clsx([styles.firstContainer])}>
        <div className={clsx(styles.firstSection)}>
          <div>
            <div className={styles.title}>
              Travel, elevated.
            </div>
            <div className={styles.subtitle}>
              Book premium vacation rentals, company retreats, and business travel
              with Rove.
            </div>
          </div>
          <MarketDateSelector
              markets={markets}
              onSearch={onMarketAndDatesSelected}
              containerStyle={styles.marketDateSelectorContainer}
          />
        </div>
        <ListingsByMarket
            markets={markets}
            listingsByMarket={listingsByMarket}
            containerStyle={styles.propertiesByLocationContainer}
        />
      </Background>
      <Background
          variant="roveOffWhite"
          containerStyle={clsx(styles.secondContainer)}
      >
        <span className={styles.title}>
          Travel differently
        </span>
        <div className={clsx(styles.selector)}>
          <div className={clsx(styles.selectorTitle)}>THE ROVE DIFFERENCE</div>
          <RoveDifferenceSelector
              containerStyle={clsx(styles.roveDifferenceSelector)}
          />
        </div>
      </Background>
      <Background variant="roveOffWhite" containerStyle={styles.thirdContainer}>
        <div className={clsx(styles.workspacesDescription)}>
          <div className={styles.leftSide}>
            <Image src={'/images/travel-differently.png'} alt="text image" />
          </div>
          <div className={styles.rightSide}>
            <span className={styles.title}>
              Better than your home office.
            </span>
            <div>
              <span className={styles.description}>
                Workspaces, fully-equipped kitchens, high end appliances, and
                group entertainment options in spaces optimized for living,
                vacationing, and working remotely.
              </span>
            </div>
          </div>
        </div>
      </Background>
      {/*<Background variant="roveWhite" containerStyle={styles.fourthContainer}>*/}
      {/*  Weekend getaways to month long workcations, Rove properties are available for as little or as long as you need.*/}
      {/*</Background>*/}
      {/*<Background variant="roveOffWhite" containerStyle={styles.fifthContainer}>*/}
      {/*  <div className={clsx(styles.workspacesDescription)}>*/}
      {/*    <div className={styles.leftSide}>*/}
      {/*      <Image src={'/images/test-image.png'} alt="text image" />*/}
      {/*    </div>*/}
      {/*    <div className={styles.rightSide}>*/}
      {/*      <span className={styles.title}>*/}
      {/*        Any Season, Any Activity.*/}
      {/*      </span>*/}
      {/*      <div>*/}
      {/*        <span className={styles.description} />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</Background>*/}
      <Background variant="roveOffWhite" containerStyle={styles.sixthContainer}>
        {featuredListings.length !== 0 && (
          <>
            <span className={styles.title}>Browse Locations</span>
            <ListingInfoList
              listings={featuredListings}
              containerStyle={styles.sliderContainer}
            />
            <Link href={'/search'}>
              <a>
                <ArrowButton
                  containerStyle={styles.button}
                  label={'View All Locations'}
                />
              </a>
            </Link>
          </>
        )}
      </Background>
      <Background
        variant="roveOffWhite"
        containerStyle={styles.seventhContainer}
      >
        <Reviews
          reviews={
            reviews.map((r) => ({
              ...r,
              listingID: featuredListings
                .find((l) => l.title.includes(r.locationName))
                ?.import_listing_id || r.listingID,
            }))
          }
          containerStyle={styles.reviewListContainer}
        />
      </Background>
    </div>
  );
};

export async function getStaticProps(): Promise<
    StaticProps | undefined
> {
  try {
    const data = await getHomepageContent();
    const collections = await getCollectionsByType(["market"]);
    return {
      props: {
        featuredListings: data!.featuredListings,
        markets: collections
          .sort((a, b) => a.order - b.order)
          .map(({ name, slug, related_listings }) =>
            ({
              name,
              slug: slug.current,
              relatedListings: related_listings,
            })
        ),
      },
      revalidate: 30,
    };
  } catch (error) {
    console.error(error);
  }
}

export default withSession(
  withLayout(Home, {
    headerProps: {
      containerStyle: styles.header,
    },
  })
);
