import { ArrowButton } from '@atoms/arrowButton';
import { ListingInfoList } from '@molecules/listingsInfoList';
import { TabsNavigation } from '@molecules/tabsNavigation';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { FeaturedListingType } from '@utils/types/featuredListing';
import { SegmentEventNamesEnum, SegmentEventPagesEnum, useAnalytics } from '@utils/hooks/useAnalytics';
import { MarketType } from '@utils/types/city';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';

type Props = {
  listingsByMarket: Record<string, FeaturedListingType[]>;
  markets: MarketType[];
} & BaseComponentProps;

export function ListingsByMarket(props: Props) {
  const { containerStyle, listingsByMarket, markets } = props;
  const [selectedLocation, setSelectedLocation] = useState<string>();
  const router = useRouter();
  const { trackEvent } = useAnalytics();

  const places = useMemo(
    () =>
      !!listingsByMarket
        ? Object.keys(listingsByMarket).map((k) => ({
            id: k,
            label: markets.find(m => m.slug === k)?.name || k,
            onClick: () => {
              setSelectedLocation(k);
              trackEvent(SegmentEventNamesEnum.FEATURED_LISTING_TAB_CLICKED, {
                PageName: SegmentEventPagesEnum.HOME_PAGE
              });
            },
          }))
        : [],
    [listingsByMarket],
  );

  useEffect(() => {
    if (!selectedLocation && places.length !== 0) {
      setSelectedLocation(places[0].id);
    }
  }, [places, selectedLocation]);

  const onViewAllPropertiesClick = () => {
    router.push({
      pathname: `/collections/${selectedLocation}`,
    });
  };

  return (
    <div className={clsx(styles.container, containerStyle)}>
      <TabsNavigation
        tabs={places}
        initialTab={0}
        containerStyle={styles.tabsContainer}
      />
      <ListingInfoList
        containerStyle={styles.propertiesContainer}
        listings={
          selectedLocation ? listingsByMarket[selectedLocation] : []
        }
      />
      <ArrowButton
        label={'View All Properties'}
        containerStyle={styles.button}
        onClick={onViewAllPropertiesClick}
      />
    </div>
  );
}
