import { BaseButtonProps } from '@utils/types/baseComponents';
import { ArrowLeft, ArrowRight } from 'assets/icons';
import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { colors } from '@utils/constants/colors';

type Props = {
  arrowPosition: 'left' | 'right';
} & BaseButtonProps;

ArrowButton.defaultProps = {
  label: 'Arrow Button',
  arrowPosition: 'right',
};

/**
 * Default arrow button
 */
export function ArrowButton(props: Props) {
  const { label, onClick: onClick, arrowPosition, containerStyle } = props;
  const [hovering, setHovering] = useState(false);

  return (
    <div
      className={clsx([styles.arrowButton, containerStyle])}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={onClick}>
      {arrowPosition === 'left' && (
        <div className={clsx(styles.arrowLeft)}>
          <ArrowLeft stroke={hovering ? colors.roveBlue : colors.roveBlack} />
        </div>
      )}
      {label}
      {arrowPosition === 'right' && (
        <div className={clsx(styles.arrowRight)}>
          <ArrowRight stroke={hovering ? colors.roveBlue : colors.roveBlack} />
        </div>
      )}
    </div>
  );
}
