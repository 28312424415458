import { BaseComponentProps } from '@utils/types/baseComponents';
import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';
import { ListingInfoCard } from '@atoms/listingInfoCard';
import { FeaturedListingType } from '@utils/types/featuredListing';

type Props = {
  layout: 'horizontal' | 'vertical';
  listings: FeaturedListingType[];
} & BaseComponentProps;

ListingInfoList.defaultProps = {
  layout: 'horizontal',
};

export function ListingInfoList(props: Props) {
  const { containerStyle, listings } = props;

  const renderListingCard = (listing: FeaturedListingType, index: number) => (
    <ListingInfoCard key={index} listing={listing} />
  );

  return (
    <div className={clsx([styles.container, containerStyle])}>
      {listings.map(renderListingCard)}
    </div>
  );
}
