import { BaseComponentProps } from '@utils/types/baseComponents';
import { colors } from '@utils/constants/colors';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import styles from './styles.module.scss';

type Props = {
  withImage: boolean;
  variant: keyof typeof colors;
  inlineStyles?: any;
} & BaseComponentProps;

Background.defaultProps = {
  withImage: false,
  variant: colors.roveWhite,
  inlineStyles: {},
};

/** Background component */
export function Background(props: PropsWithChildren<Props>) {
  const { children, containerStyle, variant, withImage, inlineStyles } = props;

  return (
    <div
      className={clsx([
        withImage && styles.backgroundImage,
        styles.container,
        containerStyle,
      ])}
      style={{ backgroundColor: colors[variant], ...inlineStyles }}>
      {children}
    </div>
  );
}
