import React from 'react';
import NextImage, { ImageProps } from 'next/image';
import { BaseComponentProps } from '@utils/types/baseComponents';
import clsx from 'clsx';

type Props = {
  src: string;
  alt: ImageProps['alt'];
  onClick: (image: string) => void;
} & BaseComponentProps &
  ImageProps;

Image.defaultProps = {
  layout: 'fill',
  objectFit: 'cover',
  onClick: () => {},
};

export function Image(props: Props) {
  const { containerStyle, src, alt, onClick, ...otherProps } = props;

  const onClickAction = () => {
    onClick(src);
  };

  return (
    <div className={clsx(containerStyle)} onClick={onClickAction}>
      <NextImage src={src} alt={alt} {...otherProps} />
    </div>
  );
}
