import { BaseComponentProps } from '@utils/types/baseComponents';
import { ReviewType } from '@utils/types/review';
import { Image } from '@atoms/image';
import { Rating } from '@atoms/rating';
import { ArrowLeft, ArrowRight } from 'assets/icons';
import clsx from 'clsx';
import Link from 'next/link';
import React, { useState } from 'react';
import styles from './styles.module.scss';

type Props = {
  review: ReviewType;
  onNextClick: () => void;
  onPreviousClick: () => void;
} & BaseComponentProps;

export function ReviewCard(props: Props) {
  const { review, containerStyle, onNextClick, onPreviousClick } = props;
  const [readMore, setReadMore] = useState(false);

  return (
    <div className={clsx([styles.container, containerStyle])}>
      <div className={styles.imageContainer}>
        <Image src={review.image} alt={'Location image'} />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.body}>
          <div className={styles.header}>
            {review.userInfo.profilePicture && (
                <div className={styles.profilePictureContainer}>
                  <Image src={review.userInfo.profilePicture} alt={'User image'} />
                </div>
            )}
            <div className={styles.info}>
              <span className={styles.name}>{review.userInfo.userName}</span>
              <span className={styles.company}>
                {review.userInfo.companyName}
              </span>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.description}>
              <Link href={`/listing/${review.listingID}`}>
                <a>
                  <div className={styles.locationName}>{review.locationName}</div>
                </a>
              </Link>
              <Rating
                rate={review.rating}
                containerStyle={styles.ratingContainer}
              />
              <div className={clsx([styles.review, readMore && styles.readMore])}>
                {review.review}
              </div>
              <div
                className={styles.expandText}
                role="button"
                onClick={() => setReadMore((v) => !v)}
              >
                {readMore ? 'Read Less' : 'Read More'}
              </div>
            </div>
            <div className={styles.arrows}>
              <ArrowLeft className={styles.arrow} onClick={onPreviousClick} />
              <ArrowRight className={styles.arrow} onClick={onNextClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
