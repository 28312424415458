import { Image } from '@atoms/image';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { FeaturedListingType } from '@utils/types/featuredListing';
import { Carousel } from 'react-responsive-carousel';
import clsx from 'clsx';
import React, { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ChevronLeft, ChevronRight } from '../../assets/icons';
import styles from './styles.module.scss';

type Props = {
  photos: FeaturedListingType["photos"];
  onListingClick?: () => void;
  autoPlay?: boolean;
  htmlImages?: boolean;
} & BaseComponentProps;

export function ListingPhoto(props: Props) {
  const {
    photos,
    onListingClick = () => {},
    autoPlay,
    htmlImages,
    containerStyle,
  } = props;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const isMobile = useIsMobile();

  return (
    <div
      className={clsx([containerStyle, styles.carouselWrapper])}
      onClick={(e) => e.preventDefault()}
    >
      {!isMobile && (
        <section className={styles.arrowsWrapper}>
          <button
            className={clsx([styles.arrow])}
            onClick={() => setCurrentImageIndex(currentImageIndex - 1)}
            disabled={currentImageIndex === 0}
          >
            <ChevronLeft />
          </button>
          <button
            className={clsx([styles.arrow])}
            onClick={() => setCurrentImageIndex(currentImageIndex + 1)}
            disabled={currentImageIndex === photos.length - 1}
          >
            <ChevronRight />
          </button>
        </section>
      )}
      <Carousel
        selectedItem={currentImageIndex}
        onChange={setCurrentImageIndex}
        width="100%"
        showArrows={false}
        showIndicators={false}
        showThumbs={false}
        showStatus={isMobile}
        infiniteLoop={false}
        onClickItem={onListingClick}
        transitionTime={200}
        autoPlay={autoPlay}
      >
        {photos.map((p, key) => {
          if (htmlImages) {
            return (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                src={p}
                alt="Listing"
                className={styles.image}
                key={`photo--${key}`}
              />
            );
          }
          return (
            <Image
              src={p}
              alt="Listing"
              containerStyle={styles.image}
              key={`photo--${key}`}
            />
          );
        })}
      </Carousel>
    </div>
  );
}
