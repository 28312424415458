import { ReviewCard } from '@molecules/reviewCard';
import { BaseComponentProps } from '@utils/types/baseComponents';
import { ReviewType } from '@utils/types/review';
import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './styles.module.scss';

type Props = {
  reviews: ReviewType[];
} & BaseComponentProps;

export function Reviews(props: Props) {
  const { containerStyle, reviews } = props;
  const [currentReview, setCurrentReview] = useState(0);

  const nextClick = () => {
    if (currentReview === reviews.length - 1) {
      setCurrentReview(0);
    } else {
      setCurrentReview((current) => current + 1);
    }
  };

  const prevClick = () => {
    if (currentReview === 0) {
      setCurrentReview(reviews.length - 1);
    } else {
      setCurrentReview((current) => current - 1);
    }
  };

  return (
    <div className={clsx([styles.container, containerStyle])}>
      <ReviewCard
        review={reviews[currentReview]}
        onNextClick={nextClick}
        onPreviousClick={prevClick}
      />
      <div className={styles.count}>
        <b>{currentReview + 1}</b> / {reviews.length}
      </div>
    </div>
  );
}
